@import "vars";
.Loader {
  text-align: center;
  color: $color4;
  text-align: center;
  margin-top: 20px;
  img {
    max-width: 100px;
    display: block;
    animation: spinner linear 1.5s infinite;
    margin: 0 auto;
  }
  svg {
    max-width: 100px;
    display: block;
    animation: spinner linear 1.5s infinite;
    margin: 0 auto;
  }
}


@keyframes spinner {
  0% {
    transform: rotate(0) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1.3);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}
.overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0,0,0, 0.3);
  z-index: 10;
  top: 0;
  left: 0;
  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    width: 200px;
    height: 10px;
    outline: 4px solid $color8;
    top: 50%;
    left: 50%;
    animation: loading 1.5s infinite linear;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 10px #fff;
  }

}
.init {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: #fff;
  z-index: 10;
  top: 0;
  left: 0;
  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    width: 200px;
    height: 10px;
    outline: 4px solid $color8;
    top: 50%;
    left: 50%;
    animation: loading 1.5s infinite linear;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 10px #fff;
  }
}
.header {
  height: 50px;
  background-color: $color1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  @include laptop {
    height: 70px;
  }
}
.footer {
  height: 40px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: $color1;
}

@keyframes loading {
  0% {
      border-left: 0 solid $color4;
  }

  10% {
      border-left: 0 solid $color4;
  }

  90% {
      border-left: 200px solid $color4;
  }

  100% {
      border-left: 200px solid $color4;
  }
}
