@import 'vars';

.App {
  background: $color5;
  height: 100%;
}
.AppOpen {
  overflow: auto;
  @include laptop {
    overflow: auto;
  }
}
.Main {
  padding: 50px 15px 0 15px;
  min-height: calc(100% - 40px);
  margin: 0 auto;
  max-width: 990px;
  @include laptop {
    padding-top: 70px;
  }
  @include desktop {
    max-width: 1170px;
  }
}

.Footer {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color1;
  color: #fff;
  font-weight: bold;
}