.Lang {
  
}
.button {
  padding: 0;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  margin: 5px;
  opacity: 0.5;
  img {
    vertical-align: middle;
  }
}
.buttonActive {
  opacity: 1;
}