@import 'vars';
* {
  box-sizing: border-box;
}
html {
  font-size: 10px;
  height: 100%;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font-size;
  height: 100%;
}
#root {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
  @include laptop {
    width: 10px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: $color7;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color2;
}

.street {
  font-weight: 600;
}
.skeleton-translate {
  display: block;
  height: 1em;
  width: 200px;
  background-color:$color13;
}